import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  WrapperLight,
  WrapperDarkGreen,
  WrapperLightGreen,
  Content,
} from "../components/section-styling"
import { breakpoints } from "../utils/breakpoints"
import styled from "styled-components"
import Accordion from "../components/Accordion"

const AboutContent = styled(Content)`
  @media ${breakpoints.md} {
    flex-flow: row nowrap;
  }
  flex-flow: row wrap;

  .people {
    height: 500px;
    width: 500px;
    border: dotted black 2px;
  }
`

const AboutPage = () => (
  <Layout>
    <Seo title="About" />

    <WrapperLight>
      <AboutContent>
        <div>
          <h1>We're a unionized, worker-owned technology cooperative.</h1>

          <Accordion
            listItem
            title="Custom software for movements."
            text="We specialize in web/app design and development for social justice organizations, non-profits, academics, artists, and more."
          />

          <Accordion
            listItem
            title="Understanding technology at a deeper level."
            text="Bringing years of expertise from industry, academia, and social justice organizing, we understand the values that are embedded in code and design."
          />

          <Accordion
            listItem
            title="Building for social change."
            text="Inspired by the desire to promote social justice, we make beautiful, ethical, and easy to use technologies."
          />
        </div>
      </AboutContent>
    </WrapperLight>

    <WrapperDarkGreen>
      <Content>
        <div>
          <h1>Our Workers</h1>

          <p>
            Sassafras is a largely queer, neurodivergent, multiracial workplace.
            We are a remote, distributed workforce located on occupied
            Indigenous territory throughout the so-called US. Most of us started
            in traditional tech and found Sassafras as a safer haven from the
            exploitative tech industry.
          </p>

          <p>
            The best talent doesn't want to work in big tech. We want to work
            here, where our voices matter. We include:
          </p>

          <Accordion
            listItem
            title="Elizabeth"
            text="Elizabeth Mitchell (they/she) grew up in Detroit, MI and still resides in the area. They are an experienced front-end developer, project manager, and UX designer. They worked at Sassafras from 2014-2020 and rejoined in 2024. They enjoy nature, ice and roller skating, gardening, and dance. They teach creative writing and roller skating."
          />
          <Accordion
            listItem
            title="Alex"
            text="Alex is a front-end developer at Sassafras and a proud member of the Industrial Workers of the World (IWW). She was an organizer with the graduate student workers union at Northeastern University, where she received her PhD from the Khoury College of Computer Sciences. She's been involved in the labor movement for several years and is excited to be part of a worker-owned cooperative."
          />
          <Accordion
            listItem
            title="Shae"
            text="Shae (they/them) grew up in the rural rust belt and is a descendant of white settlers & Borikén immigrants. They have worked for 14 years as a consultant, project manager & operations coordinator. They are a prison & police abolitionist, anti-racist and anti-fascist. They are trying to apply horizontal, care-based and anti-oppressive principles in day to day work & mutual aid based community organizing (and are failing constantly but learning a lot). Most importantly they are an aspiring compost wizard, worm dealer and property redistributor."
          />
          <Accordion
            listItem
            title="Mariam"
            text="Mariam Asad (she/they) completed her PhD in Digital Media at Georgia Tech where her work explored alternative sociopolitical frameworks and practices for more just technology design. She has worked with organizers, city planners, elders, cyclists and has had brief stints in industry at Facebook and MailChimp. She is a designer, researcher, and facilitator, but only until capitalism finally collapses so she can co-create a bike coop/practice space/community kitchen."
          />
          <Accordion
            listItem
            title="Kevin"
            text="Kevin's been writing and publishing apps since 2007. He grew up in the midwest, graduated from the University of Michigan, worked at companies big and small, freelanced for NGOs, and finally joined Sassafras in 2019. He lives with his partner and two small cats who walk on a leash and do tricks. Kevin also performs improv comedy with a local troupe."
          />
          <Accordion
            listItem
            title="Devney"
            text="Before joining Sassafras in 2017, Devney built software with Atomic Object, community organizers, and a Silicon Valley startup. They studied computer science (MS) and anthropology (BA) at Stanford University. All these experiences taught them that technology we really need will be underfunded. They are grateful to be in a network making it possible anyway. They are white with punjabi heritage, currently able, upper class, and queer as hell. They like to host, garden, read, dance, and sew."
          />
          <Accordion
            listItem
            title="Anonymous"
            text="This team member joined Sassafras Tech Collective in September 2015 and became a full worker/owner in June 2016. They bring to Sassafras extensive coding knowledge and experience, especially with Ruby on Rails, and a deep commitment to social justice."
          />
          <Accordion
            listItem
            title="Jill"
            text="Jill Dimond (she/they) has over 15 years of experience in tech holding diverse roles such as designer, researcher, and developer. She holds a PhD in Human Centered Computing from Georgia Tech, and a computer science in engineering degree from the University of Michigan. She has published many scholarly works in HCI and has been awarded grants from the NSF, NIH, NCI, and the Knight Foundation. Jill is a white mixed class (professional/working) abled femme who grew up and lives on occupied Anishinaabe Aki. They are mama to two sweet and rambunctious kiddos. She is grateful to still be coding."
          />
        </div>
      </Content>
    </WrapperDarkGreen>

    <WrapperLightGreen>
      <Content>
        <div>
          <h1>What's a worker cooperative?</h1>
          <p>
            Worker cooperatives are businesses that are democratically owned and
            run by the workers themselves, as equals. Because worker co-ops
            share the benefits in the good times and the burdens in the hard
            times, data shows they are a{" "}
            <a
              href="https://truthout.org/articles/pandemic-crash-shows-worker-co-ops-are-more-resilient-than-traditional-business/"
              target="_blank"
              rel="noopener noreferrer"
            >
              more sustainable form of business
            </a>
            .
          </p>

          <p>We are also members of:</p>

          <hr />

          <h4>
            <a
              href="https://www.usworker.coop/home/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The United States Federation of Worker Cooperatives (USFWC)
            </a>
          </h4>
          <p>
            A network of democratically-owned businesses in the United States.
            They also have{" "}
            <a
              href="https://www.usworker.coop/jobs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              job postings
            </a>{" "}
            and{" "}
            <a
              href="https://www.usworker.coop/resources/"
              target="_blank"
              rel="noopener noreferrer"
            >
              resources
            </a>{" "}
            for starting your own co-op.
          </p>

          <hr />

          <h4>
            <a
              href="https://designjustice.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Design Justice Network
            </a>
          </h4>
          <p>
            Design justice rethinks design processes, centers people who are
            normally marginalized by design, and uses collaborative, creative
            practices to address the deepest challenges our communities face.
          </p>
        </div>

        <StaticImage
          src="../images/sassy-plant-08.png"
          className="large-image"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
        />
      </Content>
    </WrapperLightGreen>
  </Layout>
)

export default AboutPage
