import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &.listItem {
    margin-bottom: 0.8rem;
  }

  a:link {
    text-decoration: none;
  }

  a:focus {
    border: 1px solid red;
  }

  p {
    font-size: 1.2rem;
  }

  .more-link-wrapper {
    display: none;
  }

  .text {
    margin-bottom: 10px;
  }
`

const AccordionTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-family: Caladea, serif;
  font-weight: bold;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    flex-shrink: 0;
  }
  .title {
    padding-left: 10px;
    padding-top: 3px;
    font-size: 1.3rem;
  }
`

function Accordion(props) {
  return (
    <AccordionWrapper className={props.listItem ? "listItem" : ""}>
      <AccordionTitle>
        <StaticImage
          className="icon"
          src="../images/sassafras-favicon-green.png"
          width={25}
          layout="fixed"
          alt="Sassafras icon."
        />
        <div className="title" data-testid={props.testid + "-title"}>
          {props.title}
        </div>
      </AccordionTitle>

      {props.date && <i>{props.date}</i>}

      {/* Only create this <span> tag if we need it! */}
      {props.text && (
        <span className="text" data-testid={props.testid + "-text"}>
          {props.text}
        </span>
      )}
    </AccordionWrapper>
  )
}

export default Accordion
